body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 16px;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}

.navbar {
  flex-shrink: 0;
  padding: 0.5rem;
}

.App-header {
  flex-grow: 1;
  overflow-y: auto;
  padding: 1rem;
}

.footer {
  flex-shrink: 0;
  padding: 0.5rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  body, html {
    font-size: 14px;
  }

  .navbar, .App-header, .footer {
    padding: 0.5rem;
  }
}

/* Smaller mobile devices */
@media (max-width: 480px) {
  body, html {
    font-size: 12px;
  }

  .navbar, .App-header, .footer {
    padding: 0.25rem;
  }
}

.cv-container {
  margin: 10px auto;
}

a {
  color: #b37500;
}

.homepage {
  padding: 20px;
}
