.navbar {
  display: flex;
  background-color: #333;
  padding: 1rem;
  justify-content: space-around;
}

.nav-link {
  color: #fff;
  text-decoration: none;
  font-size: 1.2rem;
}

.nav-link:hover {
  text-decoration: underline;
}

  .navbar button {
    border: 2px solid #fff;
    color: #fff;
    text-shadow: 2px 2px 2px rgba(1, 1, 1, 1);
    padding: 5px;
    margin: 0 5px;
    width: 50px;
    height: 30px;
    cursor: pointer;
    transition: transform 0.6s;
    font-size: large;
  }
  
  .navbar button:hover {
    transform: scale(1.05);
  }
  
  .navbar .english {
    background-image: url('/public/english_flag.jpeg');
    background-size: cover;
    background-position: center;
  }
  
  .navbar .turkish {
    background-image: url('/public/turkish_flag.png');
    background-size: cover;
    background-position: center;
  }
  