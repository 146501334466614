#simple-example {
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 10px;
    max-width: 1200px;
    margin: 0 auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  #simple-example textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #ced4da;
    margin-bottom: 20px;
    padding: 10px;
    font-family: 'Courier New', Courier, monospace;
  }
  
  #simple-example code {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #f5f5f5;
    border: 1px solid #ced4da;
    border-radius: 5px;
    font-family: 'Courier New', Courier, monospace;
    white-space: pre-wrap;
    word-wrap: break-word;
    margin-bottom: 20px;
    font-size: 10px;
  }
  
  #simple-example div {
    margin-bottom: 15px;
  }
  
  #simple-example img {
    height: 480px;
    margin-right: 20px;
    border: 1px solid #ced4da;
    border-radius: 5px;
  }
  
  #simple-example button {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
  }
  
  #simple-example button:hover {
    background-color: #0056b3;
  }
  
  #result_image {
    width: 640px;
    height: 480px;
    border: 1px solid #ced4da;
    border-radius: 5px;
  }
  
  .radio-input {
    margin-right: 10px;
  }
  
  #simple-example a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }
  
  #simple-example a:hover {
    text-decoration: underline;
  }
