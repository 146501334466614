.footer {
  background-color: #f8f9fa;
  padding: 20px;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.footer-nav {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.footer-nav a {
  text-decoration: none;
  color: #000;
}

.footer-nav a:hover {
  color: #007bff;
}

/* Mobile Friendly Styles */
@media (max-width: 768px) {
  .footer-content {
      flex-direction: column;
      align-items: center;
  }

  .footer-nav {
      justify-content: center;
      gap: 10px;
  }

  .footer {
      padding: 15px;
  }
}
