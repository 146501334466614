ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  li {
    margin: 0;
    padding: 0;
  }
  